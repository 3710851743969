import { Component } from "react/cjs/react.production.min";
import "./KgsLbs.css";



class KgsLbs extends Component {

    constructor() {
        super();
        this.state = {
            LbsToKg: 0,
            KgToLbs: 0
        }
        this.kilosToLbs = this.kilosToLbs.bind(this);
        this.lbsToKilos = this.lbsToKilos.bind(this);
    }


    kilosToLbs = (event) => {
        event.preventDefault();
        let kilos = event.target[0].value;
        let roundedLbs = (kilos * 2.2).toFixed(2);
        this.setState({
            KgToLbs: roundedLbs
        });
        console.log(roundedLbs);
    }

    lbsToKilos = (event) => {
        event.preventDefault();
        let lbs = event.target[0].value;
        let roundedKgs = (lbs / 2.2).toFixed(2);
        this.setState({
            LbsToKg: roundedKgs
        });
        console.log(roundedKgs);
    }


    render() {
        return (
            <div className="kglbs-main">
                <h1>Kilos - Lbs</h1>
                <a href='./'>Home</a>
                <form className='Address-Input-Form' onSubmit={this.kilosToLbs}>
                    <h2>Convert Kilos To Lbs</h2>
                    <input type='text' name='inputAddress' autocomplete="off"></input>
                    <br></br>
                    <button>Submit</button>
                    <div>
                        {this.state.KgToLbs !== 0 &&
                            <h2 className="result">{this.state.KgToLbs}</h2>
                        }
                    </div>

                </form>
                <div>
                    <form className='Address-Input-Form' onSubmit={this.lbsToKilos}>
                        <h2>Convert Lbs To Kilos</h2>
                        <input type='text' name='inputAddress' autocomplete="off"></input>
                        <br></br>
                        <button>Submit</button>
                        <div>
                            {this.state.LbsToKg !== 0 &&
                                <h2 className="result">{this.state.LbsToKg}</h2>
                            }
                        </div>
                    </form>
                </div>
                <img className="kglbs-img" src='./img/700.jpg' alt='lift'/>
            </div>
        )
    }
}

export default KgsLbs;