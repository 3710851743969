import './Footer.css';


function Footer() {
  return(
    <div>
      <a href='https://austinpatkos.com' >
        <p className="footer-main">Site made by Austin Patkos/ZDev-9</p>
      </a>
    </div>
  ) 
  
}

export default Footer;
