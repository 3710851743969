import './Home.css';


function Page1() {
  return (
    <div className='home-main'>
      <h1 className='home-title'>Strength Site</h1>
      <div className='home-links'>
        {/* probably makethis into like a grid system */}
        {/* My favorite Equipment */}
        {/* Link to my social Media */}
        <a href='./kgslbs' className='link'>Kgs to Lbs / Lbs To Kgs</a>
        <a href='./onerepmax' className='link'>One Rep Max Calculator</a>
        <a href='./prilipinsTable' className='link'>Prilpins Table</a>
        <a href='./glossbrenner' className='link'>Glossbrenner Calculator</a>
        <a href='https://openpowerlifting.org' className='link'>Open Powerlifting</a>

        {/* seprate section for equipment */}
      </div>
      <img className='home-img' src='./img/700.jpg' />
    </div>

  )

}

export default Page1;
