import { Component } from "react/cjs/react.production.min";
import './OneRepMax.css';



class Onerepmax extends Component {

    constructor() {
        super();
        this.state = {
            result: 0
        }
        this.calculate = this.calculate.bind(this);

    }

    calculate = (event) => {
        event.preventDefault();
        let weight = event.target[0].value;
        let reps = event.target[1].value;
        if (reps <= 8 && reps >= 1) {
            let result = this.bryzcki(weight, reps);
            this.setState({ result: result });
        } else if (reps == 9) {
            let bryz = parseFloat(this.bryzcki(weight, reps));
            let eploy = parseFloat(this.eploy(weight, reps));
            let result = (eploy + bryz) / 2;
            this.setState({ result: result.toFixed(2) });
        } else if (reps > 9) {
            let result = this.eploy(weight, reps);
            this.setState({ result: result });
        } else {
            this.setState({ result: "Error" });
        }
    }

    bryzcki = (weight, reps) => {
        let result = (weight * (36 / (37 - reps)));
        return result.toFixed(2);
    }

    eploy = (weight, reps) => {
        let result = weight * (1 + (reps / 30));
        return result.toFixed(2);
    }


    render() {
        return (
            <div className="OneRepMax-Main">
                <h1>One Rep Max</h1>
                <a href='./'>Home</a>
                <div>
                    <form className='Address-Input-Form' onSubmit={this.calculate}>
                        <h2>Calculate Your One Rep Max</h2>
                        <div className="OneRepMax-Input">
                            <h1>Weight:</h1>
                            <input type='text' name='inputAddress' autocomplete="off"></input>
                        </div>
                        <div className="OneRepMax-Input">
                            <h1>Reps:</h1>
                            <input type='text' name='inputAddress' autocomplete="off"></input>
                        </div>
                        <br></br>

                        <div>
                            {this.state.result != 0 &&
                                <h2 className="result">{this.state.result}</h2>
                            }
                        </div>
                        <button className="OneRepMax-Button">Submit</button>
                    </form>
                </div>
                <img className="OneRepMax-img" src='./img/700.jpg' />
            </div>
        )
    }
}

export default Onerepmax;