import './NotFound.css';


function NotFound() {
  return(
    <h1 className="not-found-main">Page not found</h1>
  ) 
  
}

export default NotFound;
