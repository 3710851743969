import { Component } from 'react/cjs/react.production.min';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import KgsLbs from './KgsLbs';
import Onerepmax from './OneRepMax';
import NotFound from './NotFound';
import Footer from './Footer';



class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<Home/>}/>
            <Route exact path='/kgslbs' element={<KgsLbs/>}/>
            <Route exact path='/onerepmax' element={<Onerepmax/>}/>
            <Route exact path='/prilipinsTable' element={<NotFound/>}/>
            <Route exact path='/*' element={<NotFound/>}/>
          </Routes>
          <Footer/>
        </div>
      </Router>

    );
  }
}

export default App;
